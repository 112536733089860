export const Color = {
  THEME: 'rgb(27, 193, 187)',
  BGLIGHTTHEME: '#f4fbfa',
  LIGHTTHEME: 'rgba(27, 193, 187, 0.05)',
  GRAY: 'rgb(141, 141, 141)',
  LIGHTGRAY: 'rgb(223, 223, 223)',
  BGLIGHTGRAY: 'rgb(250, 250, 250)',
  BGGRAY: 'rgb(245, 245, 245)',
  WHITE: 'rgb(255, 255, 255)',
  BLACK: '#303030',
  BLUE: '#0D6DFD',
  PERSONAL: '#0571FF',
  SELF: '#FF0F77',
  LOGISTICS: '#7F00E0',
  CLICK: '#1890FF',
};

export const MyzzymColor = '#1bc1bb';

// border color
export const LightGray = '#fcfcfc';

export const Gray = '#8d8d8d';

export const MyzzymFormColor = '#00848A';

export const Black = '#303030';

export const GrayBorder = 'rgb(223, 223, 223)';

export const Orange = '#FFAB25';

export const Red = '#ff4949';

export const Blue = '#0D6DFD';

export const Green = '#10AD2F';

export const MyzzymOpacity = 'rgba(27,193,187,0.03)';

export const SHADOW = {
  boxShadow: '1px 2px 3px 0px rgba(0, 0, 0, 0.1)',
};

export const THEMESHADOW = {
  boxShadow: '0px 1px 6px 0px rgba(27, 193, 187, 0.2)',
};

export const PureBlack = '#000000';

export const White = '#ffffff';

export const PlaceholderColor = '#cdcdcd';

export const PopupDimmedColor = 'rgba(0, 0, 0, 0.6)';

export const FlexCenterCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexStartCenter = `
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexCenterStart = `
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

export const FlexCenterEnd = `
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const FlexEndCenter = `
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const FlexEndStart = `
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

export const FlexStartStart = `
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const FlexStartEnd = `
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const FlexBetweenCenter = `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FlexBetweenStart = `
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FlexBetweenEnd = `
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FlexAroundCenter = `
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const Padding = {
  3: '0.188rem', //3px
  5: '0.313rem', //5px
  8: '0.5rem', //8px
  10: '0.625rem', //10px
  12: '0.75rem', //12px
  13: '0.813rem', //13px
  14: '0.875rem',
  15: '0.938rem', //15px
  18: '1.125rem', //15px
  20: '1.25rem', //20px
  24: '1.5rem', //24px
  26: '1.625rem', //26px
  30: '1.875rem', //30px
  36: '2.25rem', //36px
  40: '2.5rem', //40px
  48: '3rem', //48px
  50: '3.125rem', //15px
};

export const ContainerPadding = '500px';

export const FixWidthContainer = '1200px';

export const HEADERHEIGHT = '77px';

export const SearchBarHeightInMap = '6.5rem';

export const SearchHeaderHeight = '60px';

export const TextLinkOpacity = '0.85';
