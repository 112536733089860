import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App';
import 'antd/dist/antd.css';
import './index.css';
import './config/firebase';
import * as serviceWorkerRegistration from 'util/sw/serviceWorkerRegistration';
import { ConfigProvider } from 'antd';
import 'moment/locale/ko';
import locale from 'antd/es/locale/ko_KR';

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <App />
  </ConfigProvider>,

  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
