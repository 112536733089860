import styled from "styled-components";
import * as styles from "util/style/Styles";
import * as mediaQuery from "components/atoms/MediaQuery";

const BasicContainer = styled.div`
  /* padding: 0 ${styles.ContainerPadding}; */
  width: ${styles.FixWidthContainer};
  margin: 0 auto;

  ${mediaQuery.isTablet} {
    width: 80%;
  }

  ${mediaQuery.isMobile} {
    width: 90%;
  }
`;

export default BasicContainer;
