import React, { Suspense } from "react";
import SearchDetailLoader from "skeleton/search/detail/SearchDetailLoader";
import { HelmetProvider } from 'react-helmet-async';
import MetaTag from "./organisms/metatag";
import sentry from "./sentry";
import lazyWithRetry from "./lazyWithRetry";

// 환경마다 Router를 동적으로 import
const Desktop = lazyWithRetry(() => import("./Desktop"), "Desktop");
const Mobile = lazyWithRetry(() => import("./Mobile"), "Mobile");
const Tablet = lazyWithRetry(() => import("./Tablet"), "Tablet");

const App = () => {

  // for error monitoring
  sentry.initiate();

  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  // console.log("isIE, ", isIE);

  if (isIE === true) {
    alert("IE 사용자는 엣지 및 크롬 등의 다른 브라우저로 접속 부탁드립니다.");
  }
  
  /**
   * fallback -  빈화면을 어떻게 표현할지, url 경로에 search 가 포함되어 있으면
   *             branch detail skeleton을 로딩한다.
   */
  return (
    <Suspense
      fallback={
        window.location.pathname.includes('search')
        ? 
          <SearchDetailLoader/>
        :
        <div></div>
      }
    >
      <HelmetProvider>
        <MetaTag
          title="마이짐(myzzym) - 짐 보관 서비스 공유 플랫폼"
          description="셀프프스토리지/개인창고/공유창고/도심형창고/소형창고/컨테이너창고/컨테이너보관/이삿짐보관 등의 짐 보관 서비스의 제공자와 이용자를 대상으로 하는 O2O 중개 서비스 입니다."
          keywords="마이짐, myzzym, 짐 보관 서비스, 마이짐 서비스, 창고 등록, 스토리지, 셀프스토리지, 지하철 보관함, 물류창고, 개인창고, 창고, 컨테이너, 공간, 빈공간, 빈 공간, 캐비넷, 대규모 짐, 짐보관, 짐 보관 "
          imgsrc="https://cdn.myzzym.com/myzzym/images/bi/myzzym-meta-image.jpg"
          url="https://myzzym.com"
          siteName="마이짐, 짐 보관 서비스 검색 플랫폼"
        />
        {/** minWidth: 992 */}
        <Desktop />
  
        {/**
         * 모바일 디바이스
         * maxWidth: 767
         * */}
        <Mobile />
  
        {/**
         * 태블릿
         * minWidth: 768, maxWidth: 991
         * */}
        <Tablet />
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
