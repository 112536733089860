import { lazy } from "react";

const lazyWithRetry = (componentImport, componentName) =>
  lazy(async () => {
    const pageForceRefreshedKey = `page-force-refreshed-${componentName}`;
    const pageForceRefreshed = JSON.parse(
      window.localStorage.getItem(pageForceRefreshedKey) || "false"
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(pageForceRefreshedKey, "false");

      return component;
    } catch (error) {
      if (!pageForceRefreshed) {
        window.localStorage.setItem(pageForceRefreshedKey, "true");
        return window.location.reload();
      }
      console.error(error);
    }
  });

export default lazyWithRetry;
