const ENV = {
  // SERVER
  HOME_SERVER: 'https://dev.myzzym.com:14000',
  API_SERVER: 'https://dev.myzzym.com:18080',
  PAY_API_SERVER: 'https://dev.myzzym.com:18081/payment',
  FUNCTIONS_SERVER:
    'https://us-central1-myzzym-dev-71655.cloudfunctions.net/api_dev',

  // PATH
  COLLECTION_PATH: 'Notification', //firestore
  IMAGE_PATH: 'images_dev_v2',
  CDN_IMAGE_PATH: 'https://cdn.myzzym.com/myzzym_dev/images',
  CDN_FILE_PATH: 'https://cdn.myzzym.com/myzzym_dev/files',
  CDN_CONTRACT_PATH: 'https://cdn.myzzym.com/myzzym_dev/contracts',

  // ID
  MYZZYM_HELP_ID: '92',
  MYZZYM_MID: 'INIpayTest',

  // THIRDPARTY
  firebaseConfig: {
    apiKey: 'AIzaSyATtCKFK-uu4hn43Ti6W3AXXHb4WqD3FA4',
    authDomain: 'myzzym-dev-71655.firebaseapp.com',
    databaseURL:
      'https://myzzym-dev-71655-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'myzzym-dev-71655',
    storageBucket: 'myzzym-dev-71655.appspot.com',
    messagingSenderId: '106203007977',
    appId: '1:106203007977:web:5cd878402b5cc8fc175978',
    measurementId: 'G-3VJ5E7Z2LZ',
    vapiKey:
      'BJrS6nJyebVv_m2dTHXJdeAQU9TXJ4y_hpidc2ohaVvjPm_e7MrEXn1XjFs6Mmbo1nUANRzOIaBl_02fWUrUCxY',
  },
  NAVER_LOGIN: {
    callbackUrl: 'https://dev.myzzym.com:14000/login',
    clientId: 'EITtIYac0y5HxXvN9mUP',
  },
  SENTRY: {
    DSN: 'https://73199b11c2674560b1ae09662bdb15f0@o4504915123896320.ingest.sentry.io/4505113990529024',
    ENABLE_ERROR_HANDLER: true,
    ENABLE_REQUEST_TRACING: false,
    TRACING_SAMPLING_RATE: 0.2,
    ORG: 'aigenn-dev-7k',
    PROJECT: 'myzzym-frontend',
    AUTH_TOKEN:
      '105abfe2fc474024881b3bc7f2903080bf211db37b1043fe8e93bec63ab92486',
  },
};

module.exports = ENV;
