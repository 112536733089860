const ENV = {
  // SERVER
  HOME_SERVER: 'https://myzzym.com',
  API_SERVER: 'https://api.myzzym.com',
  PAY_API_SERVER: 'https://pay.myzzym.com/payment',
  FUNCTIONS_SERVER: 'https://us-central1-myzzym-35069.cloudfunctions.net/api',

  // PATH
  COLLECTION_PATH: 'Notification', //firestore
  IMAGE_PATH: 'images_v2',
  CDN_IMAGE_PATH: 'https://cdn.myzzym.com/myzzym/images',
  CDN_FILE_PATH: 'https://cdn.myzzym.com/myzzym/files',
  CDN_CONTRACT_PATH: 'https://cdn.myzzym.com/myzzym/contracts',

  // ID
  MYZZYM_HELP_ID: '44',
  MYZZYM_MID: 'aigenncom1',

  // THIRDPARTY
  firebaseConfig: {
    apiKey: 'AIzaSyA37zyNijnW8wM_UtFRfeT2qENBdzc6Zas',
    authDomain: 'myzzym-35069.firebaseapp.com',
    // databaseURL: "https://myzzym-dev.firebaseio.com/",
    databaseURL: 'https://myzzym-35069.firebaseio.com',
    projectId: 'myzzym-35069',
    storageBucket: 'myzzym-35069.appspot.com',
    messagingSenderId: '829615914316',
    // "829615914316-2moi241n1ue35v6l0vrsi86boc2221ai.apps.googleusercontent.com",
    appId: '1:829615914316:web:d5cfa87a5ed8a8bf6863e9',
    measurementId: 'G-VN7XX4M75R',
    clientId: 'G-VN7XX4M75R',
    vapiKey:
      'BORsATQS7fLi9iWN0I4UXamctMLQJP7hfd3cZ4kGAdwBcEIpMXNy7xiF7bdOIw7qvoZcoqAOLvR29Uj8zIQohbc',
  },
  NAVER_LOGIN: {
    callbackUrl: 'https://myzzym.com/login',
    clientId: 'GwM566tjb3mJsUCpfCB4',
  },
  SENTRY: {
    DSN: 'https://ecec28405a9c4846b92fcc3df303c6df@o4504808614264832.ingest.sentry.io/4504831262785536',
    ENABLE_ERROR_HANDLER: true,
    ENABLE_REQUEST_TRACING: true,
    TRACING_SAMPLING_RATE: 0.2,
    ORG: 'aigenn',
    PROJECT: 'frontend',
    AUTH_TOKEN:
      '9416924f58564a86a3b5d24ece9ddfa9700bd5d1a69a4027bbac68b1a7cdd988',
  },
};

module.exports = ENV;
