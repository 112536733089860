// firebase 버전 9
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from 'firebase/firestore';

import ENV from './config';

let firebaseApp;
let analytics, messaging, firestore;
try {
  firebaseApp = initializeApp(ENV.firebaseConfig);
  analytics = getAnalytics(firebaseApp);
  messaging = getMessaging(firebaseApp);
  firestore = getFirestore(firebaseApp);
} catch (e) {
  console.error(e);
}

const IMAGE_PATH = ENV.IMAGE_PATH;
const COLLECTION = ENV.COLLECTION_PATH; //firestore

export { firebaseApp, IMAGE_PATH, COLLECTION, analytics, messaging, firestore };
