const ENV_LOCAL = require("./localConfig.js");
const ENV_DEVELOPMENT = require("./developmentConfig.js");
const ENV_PRODUCTION = require("./productionConfig.js");

const ENV =
  process.env.REACT_APP_CONFIG === "PRODUCTION"
    ? ENV_PRODUCTION
    : process.env.REACT_APP_CONFIG === "DEVELOPMENT"
    ? ENV_DEVELOPMENT
    : ENV_LOCAL;

module.exports = ENV;
