import React from "react";
import ContentLoader from "react-content-loader";
import * as mediaQuery from "components/atoms/MediaQuery";
import BasicContainer from "components/atoms/container/BasicContainer";

const SearchDetailLoader = (props) => (
  <>

  <mediaQuery.Desktop>
    <BasicContainer>
      <ContentLoader viewBox="0 0 1080 800" speed={2}>
        {/* 창고 Title */}
        <rect x="0" y="0" rx="5" ry="5" width="200" height="25" />
        <rect x="0" y="40" rx="4" ry="4" width="250" height="35" />
  
        {/* 리뷰 */}
        <rect x="920" y="40" rx="4" ry="4" width="160" height="35" />
  
        {/* 큰 이미지 */}
        <rect x="0" y="105" rx="3" ry="3" width="535" height="510" />
  
        {/* 작은 이미지 */}
        <rect x="545" y="105" rx="3" ry="3" width="265" height="250" />
        <rect x="545" y="365" rx="3" ry="3" width="265" height="250" />
        <rect x="820" y="105" rx="3" ry="3" width="265" height="250" />
        <rect x="820" y="365" rx="3" ry="3" width="265" height="250" />
  
        {/* 주소 */}
        <rect x="0" y="635" rx="3" ry="3" width="180" height="35" />
        <rect x="0" y="690" rx="3" ry="3" width="250" height="35" />
  
        {/* 일정 */}
        <rect x="645" y="635" rx="3" ry="3" width="180" height="35" />
        <rect x="645" y="690" rx="3" ry="3" width="100" height="15" />
        <rect x="760" y="690" rx="3" ry="3" width="100" height="15" />
        <rect x="875" y="690" rx="3" ry="3" width="100" height="15" />
  
        <rect x="645" y="710" rx="3" ry="3" width="100" height="15" />
        <rect x="760" y="710" rx="3" ry="3" width="100" height="15" />
        <rect x="875" y="710" rx="3" ry="3" width="100" height="15" />
      </ContentLoader>
    </BasicContainer>
  </mediaQuery.Desktop>

  {/* <mediaQuery.Tablet>

  </mediaQuery.Tablet>

  <mediaQuery.Mobile>

  </mediaQuery.Mobile> */}
</> 
  
  
);

export default SearchDetailLoader;
